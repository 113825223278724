import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        themes: {
            options: { customProperties: true },
            light: {
                background: '#FFFFFF',
                surface: '#FFFFFF',
                primary: '#FF9800',
                secondary: '#388E3C',
                lightSurface: '#FFF'
            },

            dark: {
                primary: '#FF9800',
                secondary: '#388E3C',
                surface: '#232323',
                lightSurface: '#424242',
                red: '#F44336',
                pink: '#E91E63',
                purple: '#9C27B0',
                "deep-purple": '#673AB7',
                indigo: '#3F51B5',
                blue: '#2196F3',
                "light-blue": '#03A9F4',
                cyan: '#00BCD4',
                teal: '#009688',
                green: '#4CAF50',
                "light-green": '#8BC34A',
                lime: '#CDDC39',
                yellow: '#FFEB3B',
                amber: '#FFC107',
                orange: '#FF9800',
                "deep-orange": '#FF5722',
                brown: '#795548',
                "blue-grey": '#607D8B',
                grey: '#9E9E9E',
                black: '#000000',
                white: '#FFFFFF',
            },
        }
    }
});
