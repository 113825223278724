<template>
    <v-app @scroll="con.log($event)" class="">
        <v-app-bar
                app
                color="#121212"
                dark
                v-resize="checkSize"
                height="70px"
        >
            <v-spacer></v-spacer>

            <div style="max-width: 1366px; width: 100%">
                <div class="d-flex align-center">
                    <div class="d-flex align-center flex-grow-1">
                        <img alt="logo" src="./assets/watch_dark-01.svg" height="30" class="pr-2"/>
                        <img alt="logo" src="./assets/text_only_no_subtext_white-01.svg" height="24"/>
                    </div>


                    <v-scroll-x-transition hide-on-leave>
                        <div class="d-flex align-center" v-if="width >= 1044">
                            <a
                                    v-for="page in pages"
                                    :key="page"
                                    style="text-decoration: none"
                                    :href="`#${page}`"
                            >
                                <v-btn
                                        color="primary"
                                        text
                                        depressed
                                        class="mx-2"
                                >
                                    {{page}}
                                </v-btn>
                            </a>

                            <a
                                    style="text-decoration: none"
                                    href="https://docs.tracklogix.app"
                            >
                                <v-btn
                                        color="primary"
                                        text
                                        depressed
                                        class="mx-2"
                                >
                                    Docs
                                </v-btn>
                            </a>

                            <a
                                    style="text-decoration: none"
                                    href="https://trl.tracklogix.app"
                            >
                                <v-btn class="mx-2 font-weight-black" color="primary" outlined>Login</v-btn>
                            </a>

                            <a
                                    style="text-decoration: none"
                                    href="https://trl.tracklogix.app?signup=true"
                            >
                                <v-btn class="mx-2 gradient-background">Sign Up</v-btn>
                            </a>
                        </div>
                    </v-scroll-x-transition>
                    <v-scroll-x-transition hide-on-leave>
                        <div v-if="width < 1044">

                            <v-menu v-model="drawer">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                            color="primary"
                                            dark
                                            icon
                                            v-on="on"
                                    >
                                        <v-icon>mdi-menu</v-icon>
                                    </v-btn>
                                </template>
<!--                                <v-list>-->
<!--                                    <v-list-item-->
<!--                                            v-for="(item, index) in items"-->
<!--                                            :key="index"-->
<!--                                    >-->
<!--                                        <v-list-item-title>{{ item.title }}</v-list-item-title>-->
<!--                                    </v-list-item>-->
<!--                                </v-list>-->

                                <v-card class="py-2 px-2" width="150">
                                    <a
                                            v-for="page in pages"
                                            :key="page"
                                            style="text-decoration: none"
                                            :href="`#${page}`"
                                    >
                                        <v-btn
                                                color="primary"
                                                text
                                                depressed
                                                class="my-1"
                                                block
                                        >
                                            {{page}}
                                        </v-btn>
                                    </a>

                                    <a
                                            style="text-decoration: none"
                                            href="https://docs.tracklogix.app"
                                    >
                                        <v-btn
                                                color="primary"
                                                text
                                                depressed
                                                class="my-1"
                                                block
                                        >
                                            Docs
                                        </v-btn>
                                    </a>

                                    <div>
                                        <a
                                                style="text-decoration: none"
                                                href="https://trl.tracklogix.app"
                                        >
                                            <v-btn block class="my-1 font-weight-black" color="primary" outlined>Login</v-btn>
                                        </a>
                                    </div>
                                    <div>
                                        <a
                                                style="text-decoration: none"
                                                href="https://trl.tracklogix.app?signup=true"
                                        >
                                            <v-btn block class="my-1 gradient-background">Sign Up</v-btn>
                                        </a>
                                    </div>


                                </v-card>
                            </v-menu>




<!--                            <div class="d-flex align-center justify-center flex-grow-1 pt-4">-->
<!--                                <img alt="logo" src="./assets/watch_dark-01.svg" height="30" class="pr-2"/>-->
<!--                                <img alt="logo" src="./assets/text_only_no_subtext_white-01.svg" height="24"/>-->
<!--                            </div>-->
                        </div>
                    </v-scroll-x-transition>
                </div>
            </div>
            <v-spacer></v-spacer>
        </v-app-bar>

        <v-main class="mx-lg-12 mx-md-4 mt-6" >
<!--Dashboard Preview-->
            <div id="Home" style="position: relative; overflow-x: visible;" class="mb-16" >
                <div class="d-flex flex-wrap" style="overflow-x: visible">
                    <div class="flex-grow-1 flex-equal d-flex justify-center align-center" :style="smallScreen ? 'min-width: 100%' : null" style="overflow-x: visible">
                        <div style="max-width: 600px; overflow-x: visible" class="px-4" >
                            <div class="text-h3 font-weight-bold my-4 text-center">
                                The all in one-stop <span class="gradient-text">Workforce Analytics & Productivity</span> platform.
                            </div>

                            <div class="my-4 mt-8 text-center body-1">
                                TrackLogix will revolutionize your insight into your team’s productivity. Our software is super
                                powerful, intuitive, and incredibly easy to use. TrackLogix, has you covered!
                            </div>

                            <div class="d-flex justify-center" style="overflow-x: visible">
                                <a
                                        style="text-decoration: none"
                                        href="https://trl.tracklogix.app?signup=true"
                                >
                                    <v-btn class="my-4 gradient-background">Get Started</v-btn>
                                </a>
                            </div>

                            <div class="my-4 body-2 text-center">
                                No credit card needed. Cancel anytime.
                            </div>
                        </div>

                    </div>

                    <div class="flex-grow-1 flex-equal d-flex align-center">
                        <div class="flex-grow-1 pl-12">
                            <img
                                    style="width: 110%"
                                    alt="dashboard"
                                    src="./assets/dashboard.svg"
                            />
                        </div>
                    </div>
                </div>
            </div>
<!--Customers WHo Love Us-->
            <div class="d-flex flex-column align-stretch py-16">
                <div class="text-h6 grey--text text-center">
                    Customers who love us
                </div>
                <div class="d-flex align-center justify-center flex-wrap grey--text text--darken-1">
                    <img
                            alt="logo"
                            class="mx-8 mt-4"
                            src="./assets/customers/smitandkie.svg"
                            style="max-width: 100px; max-height: 100px; width: 100%"
                    >
                    <img
                            alt="logo"
                            class="mx-8 mt-4"
                            src="./assets/customers/letaba.svg"
                            style="max-width: 100px; max-height: 100px; width: 100%"
                    >
                    <img
                            alt="logo"
                            class="mx-8 mt-4"
                            src="./assets/customers/nbm.svg"
                            style="max-width: 100px; max-height: 100px; width: 100%"
                    >
                    <img
                            alt="logo"
                            class="mx-8 mt-4"
                            src="./assets/customers/kenny.svg"
                            style="max-width: 100px; max-height: 100px; width: 100%"
                    >
                </div>
            </div>
<!--Top Features-->
            <div id="Features" class="d-flex justify-sm-center py-16" >
                <div class="d-flex flex-wrap" style="max-width: 1366px; width: 100%">

                    <div class="flex-grow-1 flex-equal d-flex justify-center align-center" :style="smallScreen ? 'min-width: 100%' : null">
                        <div style="max-width: 600px" class="px-4">
                            <div class="text-h3 font-weight-bold my-4 text-end">
                                <span class="gradient-text">Balance</span> Workloads & <span class="gradient-text">Optimise</span> Workflows
                            </div>
                            <div class="my-4 mt-8 body-1 text-end">
                                Real-time monitoring with in-depth workplace productivity reports will aid in identifying
                                bottlenecks, strained employees, and departments.
                                <br>
                                <br>
                                Assist in balancing workloads and enhancing
                                workflows, ultimately increasing employee efficiency, productivity, and wellness.
                            </div>
                        </div>
                    </div>

                    <div class="flex-grow-1 flex-equal flex-column justify-space-between d-flex align-center px-4 ">
                        <v-card>
                            <v-card-title class="text-h5 font-weight-thin gradient-text font-weight-black">
                                <v-icon large class="gradient-text" left>mdi-account-clock</v-icon>
                                Employee Monitoring
                            </v-card-title>

                            <v-card-text>
                                Measure and boost workforce productivity by capturing and analysing activities in real-time,
                                across employees, teams, and devices
                            </v-card-text>
                        </v-card>

<!--                        <v-card class="my-9">-->
<!--                            <v-card-title class="text-h5 font-weight-thin gradient-text font-weight-black">-->
<!--                                <v-icon large class="gradient-text" left>mdi-strategy</v-icon>-->
<!--                                Apps and Websites Usage-->
<!--                            </v-card-title>-->

<!--                            <v-card-text>-->
<!--                                Find out what are your most used apps and websites per employee, team, or on a company-wide-->
<!--                                level. TRACKLOGIX will report it all at a click of a button!-->
<!--                            </v-card-text>-->
<!--                        </v-card>-->

                        <v-card>
                            <v-card-title class="text-h5 font-weight-thin gradient-text font-weight-black">
                                <v-icon large class="gradient-text" left>mdi-web</v-icon>
                                Apps and Websites Usage
                            </v-card-title>

                            <v-card-text>
                                Find out what are your most used apps and websites per employee, team, or on a company-wide
                                level. TRACKLOGIX will report it all at a click of a button!
                            </v-card-text>
                        </v-card>
                    </div>
                </div>
            </div>
<!--More Features-->
            <div style="position: relative" class="mb-16">
                <div class="d-flex flex-wrap">
                    <div class="px-4 flex-grow-1 flex-equal d-flex justify-center align-stretch" :style="smallScreen ? 'min-width: 100%' : null">
                        <div class="flex-grow-1 flex-equal px-4 mb-12">
                            <img src="./assets/screenshots.png" alt="screenshots" style="width: 100%"/>
                        </div>
                        <div class="flex-grow-1 flex-equal px-4" style="margin-top: 20%">
                            <img src="./assets/hardware.png" alt="screenshots" style="width: 100%"/>
                        </div>
                    </div>

                    <div class="d-flex flex-column align-center justify-center flex-grow-1 flex-equal d-flex px-4">

<!--                        <div class="">-->
<!--                            <div class="text-h3 font-weight-bold mt-4 text-center ">-->
<!--                                <v-icon class="gradient-text" left style="font-size: 1.1em">mdi-cellphone-link</v-icon>-->
<!--                                <span class="gradient-text">Screen & Call Monitoring</span>-->
<!--                            </div>-->

<!--                            <div class="body-1 text-center mt-4" style="max-width: 600px">-->
<!--                                Make your team’s work visible in visually beautiful dashboards and drill-downs.-->
<!--                                <br>Capturing screenshots, IP telephone calls, app and website usage.-->
<!--                                <br>RICA and POPIA compliant solution, so no stress!-->
<!--                            </div>-->
<!--                        </div>-->


<!--                        <div class="mt-8">-->
<!--                            <div class="text-h3 font-weight-bold mt-4 text-center ">-->
<!--                                <v-icon class="gradient-text" left style="font-size: 1.1em">mdi-package-variant</v-icon>-->
<!--                                <span class="gradient-text">Asset Tracking</span>-->
<!--                            </div>-->

<!--                            <div class="body-1 text-center mt-4" style="max-width: 600px">-->
<!--                                Tracklogix will create a full database of all devices linked making it super easy to have all-->
<!--                                this data at a click of a button.-->
<!--                            </div>-->
<!--                        </div>-->



                        <v-card class="my-9" style="max-width: 700px">
                            <v-card-title class="text-h5 font-weight-thin gradient-text font-weight-black">
                                <v-icon large class="gradient-text" left>mdi-cellphone-link</v-icon>
                                Screen & Call Monitoring
                            </v-card-title>

                            <v-card-text>
                                Make your team’s work visible in visually beautiful dashboards and drill-downs. Capturing
                                screenshots, IP telephone calls, app and website usage. RICA and POPIA compliant solution,
                                so no stress!
                            </v-card-text>
                        </v-card>

                        <v-card  class="my-9" style="max-width: 700px">

                            <v-card-title class="text-h5 font-weight-thin gradient-text font-weight-black">
                                <v-icon large class="gradient-text" left>mdi-package-variant</v-icon>
                                Asset Tracking
                            </v-card-title>

                            <v-card-text>
                                Tracklogix will create a full database of all devices linked making it super easy to have all
                                this data at a click of a button.
                            </v-card-text>

                        </v-card>

                    </div>
                </div>
            </div>
<!--Activity Tracking-->
            <div style="position: relative" class="mb-16">
                <div class="d-flex flex-wrap">

                    <div class="d-flex flex-column align-center justify-center flex-grow-1 flex-equal d-flex">


                        <div class="mt-8">
                            <div class="text-h3 font-weight-bold mt-4 text-center" style="max-width: 800px">
                                <span class="gradient-text">App, Voice Call & Website</span> Categories
                            </div>

                            <div class="body-1 text-md-left  mt-4" style="max-width: 800px">
                                Based on your selected labels in TrackLogix will create productivity metrics automatically.
                            </div>
                            <div class="body-1 text-md-left  mt-4" style="max-width: 800px">Label apps, and websites your team uses as productive, unproductive, and neutral, on a team or individual level.</div>
                            <div class="body-1 text-md-left  mt-4" style="max-width: 800px">
                                Employees spend too much time on telephone calls, we will tell you how much time and with whom they were talking.
                                <br>Simply let us link your CLOUD PBX telephone system and we will do the rest.
                            </div>
                            <div class="body-1 text-md-left  mt-4" style="max-width: 800px">
                                Create rules and allow certain teams to use apps and websites and others not.
                                <br>
                                TrackLogix will cater to almost any rule you can think of.
                            </div>
                        </div>

                    </div>

                    <div class="px-4 flex-grow-1 flex-equal d-flex justify-center align-stretch mt-8" :style="smallScreen ? 'min-width: 100%' : null">
                        <div class="flex-grow-1 flex-equal px-4 d-flex justify-center">
                            <img src="./assets/radial.svg" alt="screenshots" style="max-width: 100%; max-height: 500px"/>
                        </div>
                    </div>
                </div>
            </div>
<!--Make Employees Part Of The Process-->
            <div style="position: relative" class="mb-16">
                <div class="d-flex flex-wrap">

                    <div class="px-4 flex-grow-1 flex-equal d-flex justify-center align-stretch mt-8" :style="smallScreen ? 'min-width: 100%' : null">
                        <div class="flex-grow-1 flex-equal px-4 d-flex justify-center">
                            <img src="./assets/employees.svg" alt="employees" style="max-width: 100%; max-height: 500px"/>
                        </div>
                    </div>

                    <div class="d-flex flex-column align-center justify-center flex-grow-1 flex-equal d-flex px-4">
                        <div class="mt-8">
                            <div class="text-h3 font-weight-bold mt-4 text-center" style="max-width: 800px">
                                Make <span class="gradient-text">employees</span> part of the <span class="gradient-text">process</span>
                            </div>
                            <div class="body-1 text-md-left mt-4" style="max-width: 800px">
                                TrackLogix will change the way your employees view their time.
                                Give your employees access to their productivity data to support professional growth and provide refreshing transparency.
                                Deep productivity insights help identify the need for extra resources, support, or training.
                                Use Tracklogix and set KPI across teams and/or employees. Set up bonus payments based on productivity achieved.
                                Ever heard the old saying “Hard work may not be seen but will be rewarded”, well we will see it all! Reward team players who pull their weight!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
<!--Make Every Minute Count-->
            <v-parallax
                    src="./assets/paralax.png"
            >
                <div class="d-flex align-center justify-center flex-column">
                    <div class="text-h3 font-weight-bold mt-4 text-center" style="max-width: 800px">
                        <span class="gradient-text">Make Every Minute Count</span>
<!--                        Make <span class="gradient-text">employees</span> part of the <span class="gradient-text">process</span>-->
                    </div>
                    <div class="body-1 text-center mt-8" style="max-width: 800px">
                        Automated time tracking helps your team use their time more effectively, efficiently, and productively to elevate organization-wide performance.
                    </div>
                    <div class="d-flex justify-center mt-8">
                        <a
                                style="text-decoration: none"
                                href="https://trl.tracklogix.app?signup=true"
                        >
                            <v-btn class="my-4 gradient-background">Get Started</v-btn>
                        </a>
                    </div>
                </div>
            </v-parallax>
<!--Pricing-->
            <div class="px-8 mt-16" id="Pricing">
                <div class="text-center text-h3 font-weight-bold">
                    <span class="gradient-text">Pricing</span>
                </div>
                <div class="text-center text-h6 font-weight-light pt-4">
                    Try Tracklogix free for 14 days. No credit card required.
                </div>

                <div class="d-flex flex-wrap justify-center py-4">
<!--                    <div style="width: 350px; border-radius: 20px" class="pa-1 mx-4 my-4">-->
<!--                        <v-card class="pa-4 darken-5" style="box-shadow: 0px 0px 100px 0px rgba(254,168,53,0.1); border-radius: 20px;">-->
<!--                            <div class="body-1 font-weight-bold primary&#45;&#45;text">-->
<!--                                Starter-->
<!--                            </div>-->
<!--                            <div class="pt-2 d-flex align-end">-->
<!--                                <span class="text-h2">R100</span>-->
<!--                                <span class="flex-grow-1 caption grey&#45;&#45;text ml-2" style="line-height: 1.3">Per month<br>Per employee<br>Excl Vat</span>-->
<!--                            </div>-->
<!--                            <div class="px-4 py-4">-->
<!--                                <a-->
<!--                                        style="text-decoration: none"-->
<!--                                        href="https://trl.tracklogix.app?signup=true"-->
<!--                                >-->
<!--                                    <v-btn class="gradient-background" large block>Get Started</v-btn>-->
<!--                                </a>-->
<!--                            </div>-->
<!--                            <div class="body-1">-->
<!--                                <v-icon class="gradient-text" left>mdi-check</v-icon>-->
<!--                                6 Months tracking statistics-->
<!--                            </div>-->
<!--                            <div class="body-1">-->
<!--                                <v-icon class="gradient-text" left>mdi-check</v-icon>-->
<!--                                6 Months screenshots-->
<!--                            </div>-->
<!--                            <div class="body-1">-->
<!--                                <v-icon class="gradient-text" left>mdi-check</v-icon>-->
<!--                                4 Devices per employee-->
<!--                            </div>-->
<!--                        </v-card>-->
<!--                    </div>-->

                    <div style="width: 350px; border-radius: 24px" class="pa-1 mx-4 gradient-background my-4">
                        <v-card class="pa-4 darken-5" style="box-shadow: 0px 0px 100px 0px rgba(254,168,53,0.1); border-radius: 20px;">
<!--                            <div class="body-1 font-weight-bold primary&#45;&#45;text">-->
<!--                                Pro Plus-->
<!--                            </div>-->
                            <div class="pt-2 d-flex align-end">
                                <span class="text-h2">R125</span>
                                <span class="flex-grow-1 caption grey--text ml-2" style="line-height: 1.3">Per month<br>Per employee<br>Excl Vat</span>
                            </div>
                            <div class="px-4 py-4">
                                <a
                                        style="text-decoration: none"
                                        href="https://trl.tracklogix.app?signup=true"
                                >
                                    <v-btn class="gradient-background" large block>Get Started</v-btn>
                                </a>
                            </div>
                            <div class="body-1">
                                <v-icon class="gradient-text" left>mdi-check</v-icon>
                                12 Tracking statistics
                            </div>
                            <div class="body-1">
                                <v-icon class="gradient-text" left>mdi-check</v-icon>
                                6 Months screenshots
                            </div>
                            
                            <div class="body-1">
                                <v-icon class="gradient-text" left>mdi-check</v-icon>
                                4 Devices per employee
                            </div>
                        </v-card>
                    </div>

<!--                    <div style="width: 350px; border-radius: 20px" class="pa-1 mx-4 my-4">-->
<!--                        <v-card class="pa-4 darken-5" style="box-shadow: 0px 0px 100px 0px rgba(254,168,53,0.1); border-radius: 20px;">-->
<!--                            <div class="body-1 font-weight-bold primary&#45;&#45;text">-->
<!--                                Pro-->
<!--                            </div>-->
<!--                            <div class="pt-2 d-flex align-end">-->
<!--                                <span class="text-h2">R125</span>-->
<!--                                <span class="flex-grow-1 caption grey&#45;&#45;text ml-2" style="line-height: 1.3">Per month<br>Per employee<br>Excl Vat</span>-->
<!--                            </div>-->
<!--                            <div class="px-4 py-4">-->
<!--                                <a-->
<!--                                        style="text-decoration: none"-->
<!--                                        href="https://trl.tracklogix.app?signup=true"-->
<!--                                >-->
<!--                                    <v-btn class="gradient-background" large block>Get Started</v-btn>-->
<!--                                </a>-->
<!--                            </div>-->
<!--                            <div class="body-1">-->
<!--                                <v-icon class="gradient-text" left>mdi-check</v-icon>-->
<!--                                12 Months tracking statistics-->
<!--                            </div>-->
<!--                            <div class="body-1">-->
<!--                                <v-icon class="gradient-text" left>mdi-check</v-icon>-->
<!--                                6 Months Screenshots-->
<!--                            </div>-->
<!--                            <div class="body-1">-->
<!--                                <v-icon class="gradient-text" left>mdi-check</v-icon>-->
<!--                                4 Devices per employee-->
<!--                            </div>-->
<!--                        </v-card>-->
<!--                    </div>-->
                </div>
            </div>

        </v-main>
        <div
                style="
                    background: rgb(18,18,18);
background: linear-gradient(179deg, rgba(0,0,0,0) 0%, rgba(18,18,18,0.7) 14%, rgba(203,121,26,0.7) 14.1%, rgba(251, 180, 61, 0.7) 100%);
                "
                class="d-flex justify-center pt-16"
        >
            <div style="max-width: 1366px; width: 100%" class="d-flex flex-wrap justify-space-around">

                <div
                        class="flex-grow-1 flex-equal d-flex flex-column align-center"
                        style="min-width: 300px"
                >
                    <div class="text-h6" :class="smallScreen ? 'mt-16' : 'mt-8'">
                        Pages
                    </div>
                    <div class="my-2 white" style="width: 75px; height: 2px"></div>
                    <a
                            v-for="page in pages"
                            :key="page"
                            style="text-decoration: none"
                            :href="`#${page}`"
                    >
                        <v-btn
                                color="white"
                                text
                                depressed
                        >
                            {{page}}
                        </v-btn>
                    </a>

                    <a
                            style="text-decoration: none"
                            href="https://docs.tracklogix.app"
                    >
                        <v-btn
                                color="white"
                                text
                                depressed
                        >
                            Docs
                        </v-btn>
                    </a>

                    <a
                            style="text-decoration: none"
                            href="/website/privacy_policy.html"
                    >
                        <v-btn
                                color="white"
                                text
                                depressed
                        >
                            Privacy Policy
                        </v-btn>
                    </a>
                </div>

                <div id="Contact" class="flex-grow-1 flex-equal px-8" style="min-width: 300px">
                    <div class="mt-8 text-h6 text-center">
                        Get in touch
                    </div>
                    <div  class="d-flex justify-center">
                        <div class="my-2 white" style="width: 75px; height: 2px"></div>
                    </div>
                    <v-form ref="contact" @submit="$event.preventDefault()">
                        <v-text-field
                                color="white"
                                dark
                                outlined
                                dense
                                placeholder="Name"
                                hide-details
                                class="my-2"
                                :rules="[validationRules.required]"
                                prepend-icon="mdi-account"
                                v-model="name"
                        ></v-text-field>
                        <v-text-field
                                color="white"
                                dark
                                outlined
                                dense
                                placeholder="Email"
                                hide-details
                                class="my-2"
                                :rules="[validationRules.required, validationRules.email]"
                                prepend-icon="mdi-mail"
                                v-model="email"
                        ></v-text-field>
                        <v-textarea
                                color="white"
                                dark
                                outlined
                                dense
                                placeholder="Message"
                                hide-details
                                class="my-2"
                                :rows="3"
                                :rules="[validationRules.required]"
                                prepend-icon="mdi-message"
                                v-model="message"
                        ></v-textarea>
                    </v-form>
                    <div class="py-2 pt-1 d-flex justify-end">
                        <v-btn
                                outlined
                                color="white"
                                small
                                @click="sendMessage"
                                :loading="sendingMail"
                                :disabled="sendingMail"
                        >
                            Send Message
                        </v-btn>
                    </div>
                </div>



                <div
                        class="flex-grow-1 flex-equal d-flex flex-column align-center justify-center order-lg-first order-xl-first mt-8"
                        style="min-width: 300px"
                >
                    <img alt="logo" src="./assets/logo_full_white.svg" style="width: 450px; max-width: 90%" class="pr-2"/>
                    <div class="text-body-2 pt-8 pb-8">
                        Copyright Tasklogix (Pty) Ltd 2022
                    </div>
                </div>

            </div>
        </div>
    </v-app>
</template>

<script>

import axios from "axios";

export default {
    name: 'App',

    data: () => ({
        selectedPage: 'Home',
        pages: [
                'Home',
                'Features',
                'Pricing',
                'Contact'
        ],
        width: 1024,
        drawer: false,
        name: null,
        email: null,
        message: null,
        validationRules: {
            required: value => !!value || 'Required Value',
            email: value => {
                if (value && value !== null && value.length > 0) {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || 'Invalid Email Address';
                } else {
                    return 'Please enter a valid email address'
                }
            },
        },
        sendingMail: false
    }),
    mounted() {
        this.$vuetify.theme.dark = true
        this.checkSize();
    },
    methods: {

        sendMessage() {
            if (!this.$refs.contact.validate())
                return

            this.sendingMail = true;

            let config = {
                method: 'POST',
                url: `${window.location.protocol}//${window.location.hostname}/signup/websiteMessage`,
                data: {
                    name: this.name,
                    email: this.email,
                    message: this.message
                }
            }
            axios(config)
                    .then(response => {
                        this.sendingMail = false;
                        this.name = null;
                        this.email = null;
                        this.message = null;
                        this.$refs.contact.resetValidation()
                        alert('Message Sent')
                    })
                    .catch(e => {
                        console.log(e);
                        this.sendingMail = false;
                        alert('Error Sending Mail')
                    })
        },
        checkSize() {
            this.width = window.innerWidth
        }
    },
    computed: {
        smallScreen() {
            return this.width <= 960
        }
    }
};
</script>

<style>
    /*.text-button {*/
    /*    margin-left: 4px;*/
    /*    margin-right: 4px;*/
    /*    display: inline-block;*/
    /*}*/
</style>
